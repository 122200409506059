import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";

export default function Loyalpoints(props) {
  const [redeemPoints, setRedeemPoints] = useState("");
  const [loyalVal, setLoyalVal] = useState(false);
  const [loyalValTotal, setLoyalValTotal] = useState(false);
  const [totalLoyalty, setTotalLoyalty] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!props?.loyaltyPoints?.points) setLoyalValTotal(true);
    else {
      setLoyalValTotal(false);
    }
  }, [props.pointsToPrice, props?.loyaltyPoints?.points, redeemPoints]);

  const radeemVAl = (r) => {
    if (!redeemPoints)
      if (props.Final < r * props.pointsToPrice) {
        return setLoyalVal(true);
      } else {
        setLoyalVal(false);
      }

    if (props?.loyaltyPoints?.points < r) {
      return setTotalLoyalty(true);
    } else {
      setTotalLoyalty(false);
    }
  };

  const RedeemRequired = () => {
    if (!redeemPoints)
      return Swal.fire("warning ", "Redeem points is Required", "warning");
  };

  const tosterRedeem = () => {
    setLoader(false);
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-right",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: "Loyalty Points Redeemed ",
    });
  };

  const handleChange = (e) => {
    const regex = /^[0-9]*$/;

    if (regex.test(e)) {
      setRedeemPoints(e);
      radeemVAl(e);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === ".") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <div className="loyalpoints">
        <span>
          You have{" "}
          {props?.loyaltyPoints?.points ? props?.loyaltyPoints?.points : 0}{" "}
          points worth $
          {(props?.loyaltyPoints?.worth
            ? props?.loyaltyPoints?.worth
            : 0
          ).toFixed(2)}{" "}
        </span>
        <div className="points-input" style={{ marginTop: "5px" }}>
          {!props?.loyaltyPoints?.points ? (
            <input disabled type="number" placeholder="Enter Loyalty Points" />
          ) : (
            <input
              type="number"
              placeholder="Enter Loyalty Points"
              value={redeemPoints}
              onKeyPress={handleKeyPress}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
          )}
          {loyalVal || loyalValTotal || totalLoyalty ? (
            <button disabled style={{ background: "gray" }}>
              REDEEM
            </button>
          ) : (
            <>
              {loader ? (
                <Spinner
                  style={{ width: "60px", height: "60px" }}
                  animation="grow"
                  variant="primary"
                  className="colorBackground"
                />
              ) : (
                <button
                  onClick={() => {
                    props.setRedeempoints(redeemPoints);
                    setLoader(true);
                    props?.getQuote();
                    if (props.validLoyalty) return tosterRedeem();
                    RedeemRequired();
                  }}>
                  REDEEM
                </button>
              )}
            </>
          )}
        </div>
        {/* <span style={{ marginTop: "5px" }}>{props.showMessage}</span> */}
      </div>
    </div>
  );
}
