import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../../config";
import url from "../../config/axios";
import AddressSelector from "./AddressSelector";
import Loyalpoints from "./Loyalpoints.jsx";
import Bin from "../../Icons/Bin.png";
import { ChatContext } from "../../Contexts/ChatContext";

function CartDetails(props) {
  const { setNotificationCount } = useContext(ChatContext);
  const [modal, setModal] = useState(false);
  const [couponText, setCouponText] = useState("");
  const [couponTextUser, setCouponTextUser] = useState("");
  const [loyaltyPoints, setLoyaltyPoints] = useState([]);
  const [redeempoints, setRedeempoints] = useState("");
  const [btnVal, setBtnVal] = useState(false);
  const [dateVal, setDateVal] = useState(0);
  const [btnLoad, setBtnLoad] = useState(false);
  const [cartDetials, setCartDetials] = useState([]);
  const [validLoyalty, setValidLoyalty] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  let loggedIn = localStorage.getItem("user") ? true : false;
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  const token = JSON.parse(localStorage.getItem("Etoken"));
  let history = useHistory();

  // console.log(couponText);

  useEffect(() => {
    getQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.updateCartCount, redeempoints, couponText]);

  const getQuote = () => {
    const requestData = {
      outletChainID: chainID,
    };
    // console.log(couponText);

    if (couponText) {
      requestData.appliedCouponCode = couponText;
    }

    if (redeempoints) {
      requestData.consumedLoyaltyPoints = redeempoints;
    }

    // return console.log(requestData);
    url
      .put("/v1/user-orders-users/get-quote", requestData, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          await setCartDetials(res?.data?.data?.quote);
          setValidLoyalty(true);
        } else {
          setValidLoyalty(false);
          setCouponText("");
          setCouponTextUser("");
          setRedeempoints("");
          return Swal.fire("Oops", res.data.data.Message, "warning");
        }
      })
      .catch((e) => {
        if (
          e.response.data.Message === "Failure" &&
          e.response?.data?.data?.quote
        ) {
          setCartDetials(e.response?.data?.data?.quote);
          setCouponText("");
          setRedeempoints("");
          setCouponTextUser("");
        } else {
          setValidLoyalty(false);
          setCouponText("");
          setRedeempoints("");
          setCouponTextUser("");
        }

        return Swal.fire("Oops", e.response.data.data.message, "warning");
      });
  };

  useEffect(() => {
    if (loggedIn) {
      let x = moment()?.diff(user[0]?.dob, "years");
      setDateVal(x);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getpoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const getpoints = () => {
    url
      .put(
        `/v1/user-loyalty-points/points-info`,
        {
          userID: user[0]?.userID,
          consumerID: config.consumerId,
          getRulesHelper: "true",
        },
        {
          headers: {
            outletchainid: chainID,
            consumerid: config.consumerId,
          },
        }
      )
      .then((res) => {
        if (res.data.Message === "Success") {
          setLoyaltyPoints(res.data.data);
        } else {
          Swal.fire("Oops", res.data.data.message, "warning");
        }
      })
      .catch((e) => {
        Swal.fire("Oops", e.response.data.data.message, "warning");
      });
  };

  const GetCheckout = () => {
    if (!user) {
      return Swal.fire(" ", "Please login and continue.", "warning");
    }
    if (dateVal < 18) {
      Swal.fire(
        " ",
        "Age at least 18 Years old, to place an order.",
        "warning"
      );
      return history.push("/profile/edit-profile");
    }
    const requestData = {
      outletChainID: chainID,
    };

    if (redeempoints) {
      requestData.consumedLoyaltyPoints = redeempoints;
    }

    if (couponText) {
      requestData.appliedCouponCode = couponText;
    }
    // return console.log(requestData);
    setBtnLoad(true);
    url
      .post(`/v1/user-orders-users/checkout`, requestData, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })

      .then(async (res) => {
        if (res.data.Message === "Success") {
          setBtnLoad(false);
          props.chkoutremove();
          Swal.fire("Successfully ", "Order is placed", "success");
          getNotificationCount();
          return history.push("/profile/orders");
        } else {
          setCouponText("");
          setRedeempoints("");
          setBtnLoad(false);
        }
      })
      .catch((e) => {
        Swal.fire("Oops", e.response.data.data.message, "warning");
        setBtnLoad(false);
      });
  };

  const chkout = () => {
    if (props.textFieldCount <= 0 || props.textFieldCount === 0)
      Swal.fire("Missing ", "Out of stock", "warning");
  };

  //console.log(cartDetials?.appliedDiscounts?.couponDiscount?.discountApplied);

  const DeleteLoyaltyDiscount = async () => {
    setRedeempoints("");
    await getQuote();
  };

  const DeleteCouponDiscount = async () => {
    setCouponText("");
    setCouponTextUser("");
    // await getQuote();
  };

  const getNotificationCount = () => {
    url
      .get(`/v1/consumer-notifications-user/get-all-notifications-aggregated`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then((res) => {
        if (res.data.Message === "Success") {
          let IndividualCounter = 0;
          let GroupCounter = 0;
          let CurrentDayNotification = res.data.data.notifications.filter(
            (x) => {
              return (
                moment(x.createdAt).format("MMMM Do YYYY") ===
                moment().format("MMMM Do YYYY")
              );
            }
          );
          CurrentDayNotification.some((x) => {
            if (x.intentTo === "INDIVIDUAL") {
              if (x.isSeen === false) {
                IndividualCounter++;
              }
            }
            if (x.intentTo === "ALL") {
              if (Object.keys(x.seenBy).length === 0) {
                GroupCounter++;
              }
            }

            // Stop the iteration when isSeen is true
            return x.isSeen === true;
          });

          console.log(
            "Here is my notification count: ",
            IndividualCounter + GroupCounter
          );
          // console.log(
          //   "Here is my current day notification: ",
          //   CurrentDayNotification
          // );
          setNotificationCount(IndividualCounter + GroupCounter);
        } else {
          // alert("Not", res.data.data);
          setNotificationCount(0);
        }
      });
  };
  //console.log(couponText);
  return (
    <div className="cart-details">
      {modal && <AddressSelector setModal={setModal} />}

      <div
        className="order-summary"
        style={{
          marginTop: "57px",
          height: "580px",
          overflow: "auto",
        }}>
        <div className="sum-top">
          <div className="order-summary-txt">Order Summary</div>
          <hr />
          <div className="bill-row-div">
            <span className="bill-title" style={{ color: "#00000" }}>
              Subtotal ({cartDetials?.lineItemsInfo?.lineItems?.length}{" "}
              {cartDetials?.lineItemsInfo?.lineItems?.length > 1
                ? "items"
                : "item"}{" "}
              )
            </span>
            <span className="bill-price">
              {cartDetials?.finalSubTotal
                ? props.currency + " " + cartDetials?.finalSubTotal.toFixed(2)
                : 0}
            </span>
          </div>

          <div className="discounts-div">
            {cartDetials?.couponsInfo?.isApplicable &&
            cartDetials?.appliedDiscounts?.couponDiscount?.discountApplied ? (
              <div className="discountsAppliedDiv">
                <span className="discountsAppliedTxt">
                  Discounts applied using Coupon Code
                </span>
                <div>
                  <img
                    className="discountsAppliedBin"
                    src={Bin}
                    onClick={() => {
                      DeleteCouponDiscount();
                    }}
                    alt="bin"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="loyalpoints">
                  <div className="points-input">
                    <input
                      type="text"
                      placeholder="Enter Coupon Code"
                      style={{ marginBottom: "2px", width: "65%" }}
                      value={couponTextUser}
                      onChange={(e) => {
                        setCouponTextUser(e.target.value);
                      }}
                    />
                    <button
                      onClick={() => {
                        setCouponText(couponTextUser);
                        getQuote();
                      }}>
                      APPLY{" "}
                    </button>
                  </div>
                </div>
              </>
            )}

            {loyaltyPoints?.rulesInfo?.helperText ? (
              <div className="enjoyOff">
                <div>
                  <svg
                    style={{ margin: "0px 10px 0px 10px" }}
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.22 11.07L11.1842 2.03427C10.8229 1.67285 10.3209 1.45197 9.76865 1.45197H2.74085C1.63648 1.45197 0.73291 2.35553 0.73291 3.4599V10.4876C0.73291 11.0398 0.953779 11.5419 1.32526 11.9134L10.3609 20.9491C10.7223 21.3104 11.2244 21.5313 11.7765 21.5313C12.3288 21.5313 12.8307 21.3104 13.1922 20.9391L20.22 13.9112C20.5914 13.5497 20.8122 13.0479 20.8122 12.4956C20.8122 11.9435 20.5814 11.4314 20.22 11.07ZM4.2468 6.4718C3.41351 6.4718 2.74085 5.79915 2.74085 4.96585C2.74085 4.13256 3.41351 3.4599 4.2468 3.4599C5.08009 3.4599 5.75276 4.13256 5.75276 4.96585C5.75276 5.79915 5.08009 6.4718 4.2468 6.4718ZM16.0635 14.7747L11.7765 19.0615L7.48962 14.7747C7.03782 14.3129 6.75672 13.6903 6.75672 12.9976C6.75672 11.6122 7.88116 10.4876 9.26661 10.4876C9.95939 10.4876 10.5919 10.7688 11.0437 11.2306L11.7765 11.9534L12.5095 11.2206C12.9613 10.7688 13.5938 10.4876 14.2866 10.4876C15.672 10.4876 16.7964 11.6122 16.7964 12.9976C16.7964 13.6903 16.5154 14.3228 16.0635 14.7747Z"
                      fill="#2B302D"
                    />
                  </svg>
                </div>
                <div className="enjoyOff-text">
                  {loyaltyPoints?.rulesInfo?.helperText}
                </div>
              </div>
            ) : null}

            {cartDetials?.appliedDiscounts?.loyaltyDiscount?.discountApplied ? (
              <div className="discountsAppliedDiv">
                <span className="discountsAppliedTxt">
                  Discounts applied using loyalty points
                </span>
                <div>
                  <img
                    className="discountsAppliedBin"
                    src={Bin}
                    onClick={() => {
                      DeleteLoyaltyDiscount();
                    }}
                    alt="bin"
                  />
                </div>
              </div>
            ) : (
              <>
                <div>
                  <Loyalpoints
                    loyaltyPoints={loyaltyPoints}
                    getQuote={getQuote}
                    Final={parseInt(CartDetails.finalPrice)}
                    setRedeempoints={setRedeempoints}
                    price={CartDetails.originalSubTotal}
                    validLoyalty={validLoyalty}
                  />
                </div>{" "}
              </>
            )}
          </div>

          <div>
            <div className="bill-row-taxes">
              {cartDetials?.chargesBreakDownHierarchy?.map((tax, i) => {
                return (
                  <div className="bill-taxes" style={{ marginBottom: "15px" }}>
                    {tax?.chargeDisplayName !== "Total" ? (
                      <>
                        <span className="bill-title">
                          {tax?.chargeDisplayName}
                        </span>
                        <span className="bill-price">
                          {" "}
                          {props.currency +
                            " " +
                            parseFloat(tax?.chargedAmount)?.toFixed(2)}
                        </span>
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          <hr />
          <div className="bill-row-div">
            {cartDetials?.chargesBreakDownHierarchy?.map((tax, i) => {
              return (
                <>
                  {tax?.chargeDisplayName === "Total" ? (
                    <>
                      <span className="cart-total">
                        {" "}
                        {tax?.chargeDisplayName}
                      </span>
                      <span className="cart-total-price">
                        {props.currency +
                          " " +
                          parseFloat(tax?.chargedAmount).toFixed(2)}{" "}
                      </span>
                    </>
                  ) : null}
                </>
              );
            })}
          </div>

          {!btnLoad ? (
            btnVal || cartDetials?.finalPrice === 0 ? (
              <>
                <button
                  className="checkoutbtn"
                  style={{
                    background: "rgba(186, 186, 186, 186)",
                    width: "100%",
                    padding: "16px",
                  }}
                  onClick={() => {
                    chkout();
                  }}>
                  Place Order{" "}
                </button>
              </>
            ) : (
              <div className="checkoutbtn" onClick={() => GetCheckout()}>
                Place Order
              </div>
            )
          ) : (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Updating...
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CartDetails;
