import React, { useState } from "react";
import { Link } from "react-router-dom";

import imgEmpty from "../../images/empty-mobile.png";
import LoginModalW from "../Signup/LoginModalW";

function EmptiedWishlist(props) {
  // console.log(props);
  const [lModal, setLModal] = useState(false);
  return (
    <div className="empty-wish">
      <img src={imgEmpty} alt="" style={{ width: "334px", height: "299px" }} />
      <p>Whoops there is nothing present in your {props.message} !</p>
      {!props.bool && (
        <button style={{ color: "white" }}>
          <Link style={{ color: "white" }} to="/">
            Back to Home
          </Link>
        </button>
      )}
      {props.bool && <button onClick={() => setLModal(true)}>LOGIN</button>}
      {lModal && <LoginModalW loginM={setLModal} />}
    </div>
  );
}

export default EmptiedWishlist;
