import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

const DealIncludePagination = (props) => {
  const [currentPage, setCurrentPage] = useState(props.currentPage);
  const perPage = props.perPage;
  const totalPages = props.totalPages;

  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  // Helper function to generate page numbers
  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  // Render pagination items
  const renderPaginationItems = () => {
    const pageNumbers = generatePageNumbers();

    return (
      <>
        <Pagination.First
          disabled={currentPage === 1}
          onClick={() => handlePageChange(1)}
        />
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        {pageNumbers.map((pageNumber, index) => (
          <Pagination.Item
            key={index}
            active={pageNumber === currentPage}
            onClick={() => handlePageChange(pageNumber)}>
            {pageNumber}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        />
        <Pagination.Last
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(totalPages)}
        />
      </>
    );
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    props.setPage(pageNumber);
    // Implement your logic here to handle the page change
    // You might want to fetch new data based on the selected page number
  };

  return <Pagination>{renderPaginationItems()}</Pagination>;
};

export default DealIncludePagination;
