import React, { useContext, useEffect, useState } from "react";
import { ChatContext } from "../../Contexts/ChatContext";
import config from "../../config";
import { useParams } from "react-router-dom";
import url from "../../config/axios";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import Countdown from "react-countdown";
import DealIncludes from "./DealIncludes";
import carticon from "../../Icons/cartIcon.svg";
// import wishicon from "../../Icons/wishlistIcon.svg";
import Swal from "sweetalert2";
import moment from "moment";
import TopHeader from "../TopHeader/TopHeader";
import EmptiedWishlist from "../Profile/EmptiedWishlist";
import DealIncludePagination from "./DealIncludePagination";
require("moment-timezone");

export default function DealDetialPage() {
  const { cartCount, setCartCount, darkMode } = useContext(ChatContext);

  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  const token = JSON.parse(localStorage.getItem("Etoken"));
  let loggedIn = localStorage.getItem("user") ? true : false;

  const [dealDetial, setDealDetial] = useState([]);
  const [variant, setVariant] = useState([]);
  const [selectedDealVariant, setSelectedDealVariant] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [page, setPage] = useState(1);
  console.log(dealDetial);

  useEffect(() => {
    dealDetial?.inventories?.map((e, i) => {
      // console.log(e.variants[0]);
      if (
        dealDetial?.deal?.dealInfo?.BOGO?.buyProduct?.productID ||
        dealDetial?.deal?.dealInfo?.TIERED?.productID === e.productID
      ) {
        return setVariant(e);
      }
    });
  }, [dealDetial]);

  const params = useParams();
  let dealSlug = params.dealSlug;

  useEffect(() => {
    getDealsDetials();
  }, [page]);

  const getDealsDetials = () => {
    url
      .get(
        `v1/typed-deals-users/info-with-paginated-products?outletChainID=${chainID}&consumerID=${config.consumerId}&slug=${dealSlug}&page=${page}&limit=15`,

        {
          headers: {
            outletchainid: chainID,
            consumerid: config.consumerId,
          },
        }
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          await setDealDetial(res.data.data);
          setSelectedDealVariant(res?.data?.data?.inventories[0]?.variants[0]);
          if (res.data.data?.deal?.outletBasisConfig[chainID].neverExpires) {
            setCompleted(false);
          } else {
            setCompleted(true);
          }
        } else {
        }
      })
      .catch((e) => {
        Swal.fire("Oops", e.response.data.data.message, "warning");
        // console.log(e.response);
      });
  };

  // console.log(completed);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <div className="countdown-div">
          <div className="countdown-days">
            {days}
            <span className="countdown-title">Days</span>
          </div>
          <div className="countdown-days">
            {hours}
            <span className="countdown-title">Hours</span>
          </div>
          <div className="countdown-days">
            {minutes}
            <span className="countdown-title">Minutes</span>
          </div>
          <div className="countdown-days">
            {seconds}
            <span className="countdown-title">Seconds</span>
          </div>
        </div>
      );
    }
  };

  const timeValue = dealDetial?.deal?.outletBasisConfig[chainID].endsOn;
  const timezone = dealDetial?.timezone;
  const date = moment.tz(timeValue, timezone);
  const seconds = date.diff(moment().startOf("day"), "seconds");

  // console.log(dealDetial?.inventories[0]?.productID);

  const addToCart = () => {
    if (!loggedIn) {
      return Swal.fire("", "Please Login in to Continue", "warning");
    } else {
      url
        .put(
          `/v1/user-cart/update-item`,
          {
            variant: selectedDealVariant,
            productID: dealDetial?.inventories[0]?.productID,
            targetDealID: dealDetial.deal._id,
            outletChainID: chainID,
            purchaseQuantity: dealDetial?.deal?.dealInfo?.BOGO?.buyProduct
              ?.exactQuantity
              ? dealDetial?.deal?.dealInfo?.BOGO?.buyProduct?.exactQuantity
              : 1,
          },
          {
            headers: {
              outletchainid: chainID,
              consumerid: config.consumerId,
              auth: token,
            },
          }
        )
        .then(async (res) => {
          if (res.data.Message === "Success") {
            setCartCount(cartCount + 1);
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Cart updated successfully",
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: res.data.message,
            });
          }
        })
        .catch((e) => {
          Swal.fire("Oops", e.response.data.data.message, "warning");
          // console.log(e.response);
        });
    }
  };
  // console.log(selectedDealVariant);

  return (
    <>
      <TopHeader pageName={"Deals"} />

      {dealDetial?.deal ? (
        <div className="DealDetialPage">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-5 DealDetialPage-imageCol-phone">
              <div className="DealDetialPage-imageSection">
                <div className="DealDetialPage-imageback">
                  <div className="DealDetialPage-DealType-div">
                    <span className="DealDetialPage-DealType">
                      {dealDetial?.deal?.dealType}
                    </span>
                  </div>
                  {dealDetial?.deal?.images[0] ? (
                    <img src={dealDetial?.deal?.images[0]} alt="dealimg" />
                  ) : (
                    <img src={noimg} alt="noimg" />
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="DealDetialPage-detialSection">
                <div className="DealDetial-NameDiv">
                  <span className="DealDetial-name">
                    {dealDetial?.deal?.name}
                  </span>
                  {/* <span className="DealDetial-price">$25.99</span> */}
                </div>
                <span className="DealDetial-dec">
                  {dealDetial?.descriptionMessages?.map((d, i) => {
                    {
                      return <span className="DealDetial-dec-value">{d}</span>;
                    }
                  })}
                </span>

                {dealDetial?.deal?.dealType !== "BOGO_BUNDLED" ? (
                  <div className="dealsVarients-mainDiv">
                    {dealDetial?.inventories[0]?.variants?.map((v, i) => {
                      return (
                        <div
                          className="dealsVarients"
                          key={i}
                          onClick={() => {
                            setSelectedDealVariant(v);
                          }}
                          style={{
                            border:
                              selectedDealVariant.weight === v.weight
                                ? darkMode
                                  ? "2px solid white"
                                  : " 3px solid black"
                                : darkMode
                                ? "2px solid #7E8A98"
                                : "3px solid gray",
                          }}>
                          <span
                            className="dealsVarients-weight"
                            style={{
                              color:
                                selectedDealVariant.weight === v.weight
                                  ? darkMode
                                    ? "#FFFFFF"
                                    : "#121212"
                                  : darkMode
                                  ? "#7E8A98"
                                  : "grey",
                            }}>
                            {v.name}
                          </span>
                          <span
                            className="dealsVarients-price"
                            style={{
                              color:
                                selectedDealVariant.weight === v.weight
                                  ? darkMode
                                    ? "#FFFFFF"
                                    : "#121212"
                                  : darkMode
                                  ? "#7E8A98"
                                  : "grey",
                            }}>
                            ${v.price}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                {!completed ? null : (
                  <span>
                    <Countdown
                      date={Date.now() + seconds * 1000}
                      renderer={renderer}
                    />
                  </span>
                )}
                <span className="DealDetial-dealInc-topic">Deal Includes</span>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {dealDetial?.inventories?.map((e, i) => {
                    return (
                      <DealIncludes products={e} deal={dealDetial?.deal} />
                    );
                  })}
                </div>
                {dealDetial?.deal?.dealType === "BOGO_BUNDLED" ? (
                  <div className="dealdetials-pagination">
                    <DealIncludePagination
                      currentPage={page}
                      perPage={15}
                      totalPages={dealDetial?.paginationData?.totalPages}
                      setPage={setPage}
                    />
                  </div>
                ) : null}
                {dealDetial?.deal?.dealType !== "BOGO_BUNDLED" ? (
                  <div className="DealDetial-btn">
                    <div
                      className="DealDetial-cartbtn"
                      onClick={() => {
                        addToCart();
                      }}>
                      <div style={{ marginRight: "10px" }}>
                        <img src={carticon} alt="carticon" />
                      </div>
                      ADD TO CART
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptiedWishlist message={"Deal"} />
      )}
    </>
  );
}
