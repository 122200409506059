import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import url from "../../config/axios";
import DealsCard from "./DealsCard";
import TopHeader from "../TopHeader/TopHeader";
import EmptiedWishlist from "../Profile/EmptiedWishlist";

function DealsPage() {
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  const [deals, setDeals] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [filters, setFilters] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const token = JSON.parse(localStorage.getItem("Etoken"));

  useEffect(() => {
    getdeals();
    getfilters();
  }, [filterValues]);

  const getdeals = () => {
    let headers = {
      outletchainid: chainID,
      consumerid: config.consumerId,
    };

    url
      .put(
        `/v1/typed-deals-users/consumable-deals`,
        {
          outletChainID: chainID,
          consumerID: config.consumerId,
          dealTypes: filterValues,
        },
        { headers }
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          await setDeals(res.data.data.deals);
          setTimeZone(res.data.data.timezone);
        } else {
        }
      });
  };

  const getfilters = () => {
    url
      .get(`/v1/typed-deals-users/filter-representation`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          await setFilters(res.data.data.filters);
        } else {
        }
      });
  };

  const filterHandler = (toggle, value) => {
    let x = filterValues;
    if (toggle) {
      setFilterValues([...x, value]);
    } else {
      const updatedArray = x.filter((item) => item !== value);
      setFilterValues(updatedArray);
    }
  };

  return (
    <>
      {deals?.length < 0 ? (
        <EmptiedWishlist message={"Deals"} />
      ) : (
        <div className="deals">
          <div className="deal-fliterBox">
            <span className="deal-topic">Deals</span>
            {filters?.map((f, i) => {
              return (
                <span className="deals-filter">
                  <span className="custom-chkbox"></span>
                  <input
                    type="checkbox"
                    className="checkboxes"
                    onChange={(c) => {
                      filterHandler(c.target.checked, f.displayName);
                    }}
                  />
                  <div className="deals-filter-names">
                    <span className="deals-filter-name">{f.displayName}</span>
                    <span className="deals-filter-subtitle">
                      {f.description}
                    </span>
                  </div>
                </span>
              );
            })}
          </div>

          <div className="dealPage-products">
            {deals?.map((d, i) => {
              return <DealsCard deals={d} timeZone={timeZone} />;
            })}{" "}
          </div>
        </div>
      )}
    </>
  );
}

export default DealsPage;
