import React, { useEffect, useState } from "react";
import SkeletionCategory from "../../components/Categories/SkeletionCategory.jsx";
import BrandsCard from "../../components/Manufacturers/ManufactureCard.jsx";
import url from "../../config/axios";
import TopHeader from "../../components/TopHeader/TopHeader";
import EmptiedWishlist from "../../components/Profile/EmptiedWishlist.jsx";

function Manufacturers() {
  const [brands, setBrands] = useState([]);
  const [brandsData, setbrandsData] = useState(false);
  const [skel, setskel] = useState(true);

  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  useEffect(() => {
    url
      .get(`/brand/outlet?OutletId=${chainID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setBrands(res.data.data);
          setskel(false);
          setbrandsData(false);
        } else {
          setbrandsData(true);
        }
      })
      .catch(() => {
        setbrandsData(true);
      });
  }, [chainID]);

  return (
    <>
      <TopHeader pageName={"Brand"} total={brands.length} />

      <div
        className="container"
        style={{ maxWidth: "100%", paddingBottom: "102px" }}>
        {/* <h5 className="gray-title breadcrumbsDef"><span className="breadcrumbsDef" style={{ cursor: "pointer" }} onClick={() => history.push(`/`)}>Home</span> &nbsp;&gt;&nbsp; All Manufacturers ({brands.length})</h5> */}
        {!brandsData ? (
          <div className="all-cats">
            {skel ? (
              <>
                {" "}
                <SkeletionCategory /> <SkeletionCategory />
                <SkeletionCategory /> <SkeletionCategory />{" "}
              </>
            ) : (
              <>
                {brands.map((brd, i) => (
                  <BrandsCard
                    productCount={brd.countInventory[0].count}
                    name={brd.name}
                    image={brd.image}
                    key={i}
                    brandID={brd.brandID}
                  />
                ))}
              </>
            )}
          </div>
        ) : (
          <EmptiedWishlist message={"Brands"} />
        )}
      </div>
    </>
  );
}

export default Manufacturers;
