import { useContext } from "react";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import { Tooltip } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { ChatContext } from "../../Contexts/ChatContext";

export default function DealIncludes(props) {
  const { setRegularProduct } = useContext(ChatContext);
  // console.log(props);
  let selectedName = props?.products?.variants[0]?.name
    ? props?.products?.variants[0]?.name
    : props?.products?.product?.name;
  return (
    <div className="DealDetial-dealInc">
      <img src={noimg} alt="noimg" />
      <Link
        to={"/product/" + props?.products?.productID}
        style={{ textDecoration: "none" }}
        onClick={() => {
          setRegularProduct(props?.deal);
        }}>
        <span className="DealDetial-dealInc-name">
          {selectedName.length <= 23 ? (
            selectedName
          ) : (
            <span className="DealDetial-dealInc-name">
              <Tooltip
                content={selectedName}
                color="success"
                rounded={false}
                placement={"top"}>
                {props?.products?.product?.name?.substring(0, 23) + "..."}
              </Tooltip>
            </span>
          )}
        </span>
      </Link>
    </div>
  );
}
