import Dialog from "@mui/material/Dialog";
import Slider from "@mui/material/Slider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
//import Datepicker from "./Datepicker";
import Cropper from "react-easy-crop";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import url from "../../config/axios";
import { storage } from "../../config/firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import getCroppedImg from "../../pages/Profile/cropImage";
import { Link } from "react-router-dom";
import Input from "react-phone-number-input/input";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";

function EditProfile() {
  const user = JSON.parse(localStorage.getItem("user"));
  const uid = JSON.parse(localStorage.getItem("uid"));
  const token = JSON.parse(localStorage.getItem("Etoken"));
  // console.log(user[0]);
  //console.log(token);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [image, setImage] = useState("");
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("Male");
  const [email, setEmail] = useState("");
  const [altNo, setAltNo] = useState("");
  const [file, setFile] = useState("");
  const [snapShotValue, setSnapShotValue] = useState("");
  const [date, setDate] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [value, setValue] = useState("");
  const [profession, setProfession] = useState("Teacher");
  const [MLN, setMLN] = useState("");
  const [MLED, setMLED] = useState("");
  const [DLN, setDLN] = useState("");
  const [Password, setPassword] = useState("");

  const history = useHistory();
  //console.log(file);

  useEffect(() => {
    if (!user) return history.push("/");

    setDate(user[0]?.dob ? moment().format(user[0]?.dob?.split("T")[0]) : "");
    setEmail(user[0]?.email ? user[0]?.email : "");
    setGender(user[0]?.gender ? user[0]?.gender : "");
    setName(user[0]?.name ? user[0]?.name : "");
    setValue(user[0]?.mobileNo ? user[0]?.mobileNo : uid?.phoneNumber);
    setAltNo(uid?.altMobileNo ? uid?.altMobileNo : user[0]?.altMobileNo);
    setProfession(user[0].profession ? user[0].profession : uid?.profession);
    setMLN(
      user[0].medicalLicense ? user[0].medicalLicense : uid?.medicalLicense
    );
    setMLED(
      user[0].mlExpiry ? moment().format(user[0]?.mlExpiry?.split("T")[0]) : ""
    );
    setDLN(
      user[0].drivingLicense ? user[0].drivingLicense : uid?.drivingLicense
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!user) return history.push("/");

    setDate(user[0]?.dob ? moment().format(user[0]?.dob?.split("T")[0]) : "");
    setEmail(user[0]?.email ? user[0]?.email : "");
    setGender(user[0]?.gender ? user[0]?.gender : "");
    setName(user[0]?.name ? user[0]?.name : "");
    setValue(user[0]?.mobileNo ? user[0]?.mobileNo : uid?.phoneNumber);
    setAltNo(uid?.altMobileNo ? uid?.altMobileNo : user[0]?.altMobileNo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //new Date(moment(user[0]?.dob).format("MM/DD/YY"))
  //const defdate = date?.toLocaleDateString('en-CA')
  const handleChange = (date) => {
    setDate(date);
  };

  const handleChangeMLED = (e) => {
    setMLED(e);
    console.log(e);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        setImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {}
    },
    [croppedAreaPixels, image]
  );

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const fileUpload = () => {
    if (file !== "") {
      // setLoader(true);
      //   const uploadTask = storage.ref(storage, `images/${file.name}`);

      // const uploadTask = storage.ref(`images/${file.name}`).put(file);

      // const storageRef = ref(storage, `images/${file.name}`);
      // const uploadTask = uploadBytesResumable(storageRef, file);

      // const ref = ref(storage, `images/${file.name}`);
      // const uploadTask = uploadBytes(ref, file.name);

      // const storage = getStorage();
      // const storageRef = ref(storage, `images/${file.name}`);

      // Create file metadata including the content type
      // const metadata = {
      //   contentType: "image/jpeg",
      // };

      // Upload the file and metadata
      // const uploadTask = uploadBytes(storageRef, file, metadata);

      // uploadTask.on(
      //   "state_changed",
      //   (snapshot) => {
      //     const progress = Math.round(
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      //     );
      // console.log(snapShotValue)
      //     setSnapShotValue(progress);
      //   },
      //   (error) => {
      //     console.log(error);
      //   },
      //   () => {
      //     storage
      //       .ref("images")
      //       .child(file.name)
      //       .getDownloadURL()
      //       .then(async (blob) => {
      //         console.log(blob);
      //         await updateUser(blob);
      //       });
      //   }
      // );

      const storage = getStorage();
      const storageRef = ref(storage, `${file.name}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            console.log("File available at", downloadURL);
            await updateUser(downloadURL);
          });
        }
      );
    } else {
      updateUser("");
    }
  };

  const updateUser = (imageData) => {
    //console.log(blob);
    let body = {
      name: name,
      email: email,
      mobileNo: value,
      gender: gender,
      dob: date,
      image: imageData,
      // addresses: address ? [address] : user[0]?.addresses[0],
      profession: profession,
      password: Password,
      medicalLicense: MLN,
      mlExpiry: MLED,
      drivingLicense: DLN,
      countryCode: value,
    };

    console.log(body);

    if (!body?.name) return Swal.fire("Missing", "User Name", "warning");
    else if (!body?.email) return Swal.fire("Missing", "Email", "warning");
    else if (!body?.mobileNo)
      return Swal.fire("Missing", "Mobile Number", "warning");
    else if (!body?.profession)
      return Swal.fire("Missing", "profession", "warning");
    //else if (!body.code ) return  Swal.fire('Missing','code','warning' )
    // else if (!user[0]?.addresses[0]   ) return  Swal.fire('Missing','Addresses','warning' )
    else if (!body?.gender) return Swal.fire("Missing", "Gender", "warning");
    else if (!body?.dob)
      return Swal.fire("Missing", "Date of birth", "warning");
    // else if (!body?.image && !user[0].image  ) return  Swal.fire('Missing','Image','warning' )
    else {
      setBtnLoad(true);
      // console.log(body)
      url
        .put(`/v1/auth/update-user-profile`, body, {
          headers: {
            auth: token,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.Message === "Success") {
            localStorage.setItem(
              "user",
              JSON.stringify([res.data.data.userInfo])
            );
            Swal.fire("Successfully", "Profile updated", "success");
            history.push(`/profile/`);
          } else {
            setBtnLoad(false);
            console.log("error");
          }
        })
        .catch((e) => {
          console.log("update", e);

          setBtnLoad(false);
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "warning",
            title: !e.response?.data?.data?.message
              ? e?.response?.data?.data["body.mlExpiry"]
              : e?.response?.data?.data?.message,
          });
        });
    }
  };

  const handlerName = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setName(name);
    } else {
      setName(inputValue);
    }
  };

  const handlerDLN = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setDLN(DLN);
    } else {
      setDLN(inputValue);
    }
  };

  const handlerMLN = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setMLN(MLN);
    } else {
      setMLN(inputValue);
    }
  };

  return (
    <div>
      <div className="dashboard">
        <div className="profile-details">
          <div
            className="row phone-profile-bannerRow-editProfile"
            style={{ width: "100%" }}>
            <div className="col-8 Ep-phone-imageDiv">
              <div className="pdetails">
                {image || user[0]?.image ? (
                  <>
                    <img src={image ? image : user[0]?.image} alt="" />
                  </>
                ) : (
                  <span className="Ep-FirstLetter">
                    {user[0]?.name[0]?.toUpperCase()}
                  </span>
                )}
                <div className="Ep-changeImg">
                  {/* //////////////////////////////////////////////////////////////// */}
                  <div className="group-comb">
                    <div className="form-group img-drop">
                      <label
                        className="custom-file-upload"
                        style={{
                          marginLeft: "3%",
                          marginTop: "1%",
                          position: "absolute",
                        }}>
                        <input
                          style={{ opacity: 0, zIndex: 1, width: "100%" }}
                          type="file"
                          id="image"
                          name="Image"
                          onChange={imageSelector}
                          accept="image/png, image/jpeg"
                        />
                        <svg
                          style={{
                            marginLeft: "-11px",
                            marginTop: "-121px",
                            cursor: "pointer",
                          }}
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.42871 0L5.35731 2.66668H2.8572C1.27442 2.66668 0 3.85605 0 5.33336V13.3333C0 14.8106 1.27434 16 2.8572 16H17.1428C18.7256 16 20 14.8106 20 13.3333V5.33336C20 3.85612 18.7257 2.66668 17.1428 2.66668H14.6427L13.5713 0H6.42871ZM10.0002 5.00008C12.5561 5.00008 14.6431 6.94806 14.6431 9.33341C14.6431 11.7188 12.556 13.6667 10.0002 13.6667C7.44446 13.6667 5.35731 11.7188 5.35731 9.33341C5.35731 6.94806 7.44446 5.00008 10.0002 5.00008ZM10.0002 6.33325C8.21654 6.33325 6.78602 7.66866 6.78602 9.33313C6.78602 10.9979 8.21684 12.333 10.0002 12.333C11.7839 12.333 13.2144 10.9976 13.2144 9.33313C13.2144 7.66838 11.7839 6.33325 10.0002 6.33325Z"
                            fill="white"
                          />
                        </svg>
                      </label>
                      {/* <img style={{ border: "1px solid #0B3090", borderRadius: "22px " }}
                        alt=""
                        width="350"
                        height="350"
                        src={image ? image : user[0].image ? user[0].image : imgprofile}
                        className=" mx-auto d-block galimage profImg"
                      ></img> */}
                      {showCrop && (
                        <Dialog
                          fullScreen={fullScreen}
                          open={open}
                          fullWidth={true}
                          onClose={() => setOpen(false)}
                          aria-labelledby="responsive-dialog-title">
                          <div className="App">
                            <div className="crop-container">
                              <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={4 / 3}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                              />
                            </div>
                            <div className="controls">
                              <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(zoom)}
                                classes={{ root: "slider" }}
                              />
                            </div>
                            <div style={{ marginBottom: "3%" }}>
                              <button
                                className="btn btn-primary crop-btn"
                                onClick={(e) => showCroppedImage(e)}>
                                Crop
                              </button>
                            </div>
                          </div>
                        </Dialog>
                      )}
                    </div>
                  </div>
                  {/* //////////////////////////////////////////////////////////////// */}
                </div>
                <div
                  className="phone-pUser-Name"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginLeft: "30px",
                  }}>
                  <p className="pName">{user[0]?.name}</p>
                  {/* <span className="pAddress">Alaska, United States</span> */}
                </div>
              </div>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px",
              }}>
              <Link to="/profile">
                <div className="Ep-back">
                  <svg
                    style={{ marginRight: "10px" }}
                    className="svgColor"
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="svgColor strokeColor"
                      d="M1.21191 7.50033L7.74624 1.20653C7.80938 1.14194 7.88868 1.08993 7.97847 1.05422C8.06825 1.01852 8.16629 1 8.26557 1C8.36484 1 8.46288 1.01852 8.55266 1.05422C8.64244 1.08993 8.72175 1.14194 8.78489 1.20653C8.92386 1.34658 9 1.52337 9 1.70597C9 1.88857 8.92386 2.06536 8.78489 2.20541L2.76879 7.99977L8.78434 13.7941C8.92285 13.9344 8.99869 14.1112 8.99869 14.2938C8.99869 14.4764 8.92285 14.6532 8.78434 14.7935C8.7212 14.8581 8.6419 14.9101 8.55211 14.9458C8.46233 14.9815 8.36429 15 8.26502 15C8.16575 15 8.06771 14.9815 7.97792 14.9458C7.88814 14.9101 7.80883 14.8581 7.74569 14.7935L1.21136 8.49875C1.07459 8.35792 0.999902 8.18148 1 7.99946C1.0001 7.81743 1.07498 7.64106 1.21191 7.50033Z"
                      fill="#08754C"
                      stroke="#08754C"
                    />
                  </svg>
                  Back
                </div>
              </Link>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "22px" }}>
            <div className="col">
              <span className="Ep-label">
                Full Name*
                <br />
                <input
                  style={{ width: "100%" }}
                  type="text"
                  name=""
                  id=""
                  placeholder="Enter Name"
                  defaultValue={!user[0] ? "" : user[0]?.name}
                  value={name}
                  onChange={(e) => handlerName(e.target.value)}
                />
              </span>
            </div>

            <div className="col">
              <span className="Ep-label">
                Email*
                <br />
                <input
                  style={{ width: "100%" }}
                  type="email"
                  name=""
                  id=""
                  disabled
                  placeholder="Enter Email"
                  defaultValue={user[0]?.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </span>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "22px" }}>
            <div className="col">
              <div className="Ep-mobilenumber-lable">
                <span className="Ep-label" style={{ width: "119px" }}>
                  Mobile Number
                </span>
                {/* <span className="Ep-label">Mobile Number</span> */}
              </div>
              <div
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                }}>
                <Input
                  className="Ep-numberNumber"
                  style={{ width: "100%" }}
                  placeholder="Enter phone number"
                  value={value}
                  onChange={setValue}
                  disabled
                  defaultValue={
                    user[0]?.mobileNo ? user[0]?.mobileNo : uid?.phoneNumber
                  }
                  error={
                    value
                      ? isValidPhoneNumber(value)
                        ? undefined
                        : "Invalid phone number"
                      : "Phone number required"
                  }
                />
              </div>
            </div>

            <div className="col" style={{ display: "flex" }}>
              <span className="Ep-label">
                Gender
                <br />
                <select
                  className="Ep-selectGender"
                  value={user[0].gender}
                  placeholder="Select Gender"
                  onChange={(e) => setGender(e.target.value)}>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Rather Not Say">Others</option>
                </select>
              </span>
            </div>
          </div>

          <div
            className="row"
            style={{
              marginBottom: "22px",
              display: "flex",
              alignItems: "center",
            }}>
            <div className="col">
              <div className="Ep-label">
                <span className="Ep-label">Date Of Birth*</span>
                <br />
                {/* <DatePicker selected={date} onChange={handleChange} /> */}
                <input
                  style={{ width: "100%" }}
                  type="date"
                  disabled
                  value={date}
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="col" style={{ marginTop: "-15px" }}>
              <span style={{ flexDirection: "row" }}>
                <span className="Ep-label">
                  Profession*
                  <br />
                  <select
                    className="Ep-selectGender"
                    value={user[0].profession}
                    placeholder="profession"
                    disabled
                    onChange={(e) => setProfession(e.target.value)}>
                    <option value="Teachers">Teacher</option>
                    <option value="Veterans">Veteran</option>
                    <option value="FireFighter">FireFighter</option>
                    <option value="Nurse">Nurse</option>
                    <option value="Others">Other</option>
                  </select>
                </span>
              </span>
            </div>
          </div>

          <div
            className="row"
            style={{
              marginBottom: "22px",
              display: "flex",
              alignItems: "center",
            }}>
            <div className="col" style={{ marginTop: "-15px" }}>
              <span style={{ flexDirection: "row" }}>
                <span className="Ep-label">
                  Medical License Number*
                  <br />
                  <input
                    style={{ width: "100%" }}
                    value={MLN}
                    type="text"
                    name=""
                    id=""
                    placeholder="A1234455612"
                    onChange={(e) => {
                      handlerMLN(e.target.value);
                    }}
                  />
                </span>
              </span>
            </div>

            <div className="col">
              <div className="Ep-label">
                <span className="Ep-label">Medical License Expiry Date*</span>
                <br />
                {/* <DatePicker selected={date} onChange={handleChange} /> */}
                <input
                  style={{ width: "100%" }}
                  type="date"
                  value={MLED}
                  min={moment().format("YYYY-MM-DD")}
                  onChange={(e) => {
                    handleChangeMLED(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "22px" }}>
            <span className="Ep-label">
              Drivers License Number*
              <br />
              <input
                style={{ width: "100%" }}
                value={DLN}
                type="text"
                name=""
                id=""
                placeholder="DDWDLFB34445XX01223"
                onChange={(e) => {
                  handlerDLN(e.target.value);
                }}
              />
            </span>
          </div>

          <div className="row" style={{ marginBottom: "22px" }}>
            <span className="Ep-label">
              Password*
              <br />
              <input
                style={{ width: "100%" }}
                type="password"
                disabled
                value={"xxxxxxxx"}
                name=""
                id=""
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <div className="Ep-changepass">Change Password</div> */}
            </span>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "30px",
        }}>
        {!btnLoad ? (
          <button
            className="Ep-savebtn"
            disabled={btnLoad}
            onClick={() => {
              fileUpload();
            }}>
            Save Changes
          </button>
        ) : (
          <Button className="Ep-savebtn" variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Updating...
          </Button>
        )}
      </div>
    </div>
  );
}

export default EditProfile;
