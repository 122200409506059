import React from "react";
import { Route, Switch } from "react-router-dom";
import LiveChat from "../components/locations/LiveChat";
import Wishlist from "../components/Profile/Wishlist";
import Contactus from "../components/Signup/Contactus";
import Cart from "../pages/Cart/Cart";
import Categories from "../pages/Categories/AllCategories";
import Deals from "../pages/Deals/Deals";
import Home from "../pages/Home/Home";
import Manufacturers from "../pages/Manufacturers/Manufacturers";
import MembershipForm from "../pages/Membership/MembershipForm";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Notifications from "../pages/Notifications/Notification";
import Products from "../pages/product/Product";
import SingleProducts from "../pages/product/SingleProduct";
import Profile from "../pages/Profile/Profile";
import TermsConditions from "../pages/TermsConditions";
// import DealProduct from "./../pages/Deals/DealProduct";
import Redirect from "./../components/Redirect";
import DealDetialPage from "../components/Deals/DealDetialPage";
import DealProductItem from "../components/Deals/DealProductItem";
import AppDetialPage from "../pages/AppDetials/AppDetialPage";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/profile" component={Profile} />
        <div className="main-content">
          <LiveChat />
          <Route path="/" exact component={Home} />
          <Route path="/categories" exact component={Categories} />
          <Route
            path="/products/category/:category?"
            exact
            component={Products}
          />
          <Route
            path="/products/manufacturers/:brandID?"
            exact
            component={Products}
          />
          {/* <Route
            path="/products/Deals/:dealId?"
            exact
            component={DealProduct}
          /> */}
          {/* <Route path="/products/Deal/:dealId?" exact component={Products} /> */}
          <Route path="/products/flash/:flash?" exact component={Products} />
          <Route
            path="/products/popular/:popular?"
            exact
            component={Products}
          />
          <Route
            path="/products/trending/:trending?"
            exact
            component={Products}
          />
          <Route path="/products/search/:search?" exact component={Products} />
          <Route path="/manufacturers" exact component={Manufacturers} />
          <Route path="/deals" exact component={Deals} />
          <Route path="/deals/:dealSlug" exact component={DealDetialPage} />
          <Route
            path="/deals/regular/:dealSlug"
            exact
            component={DealProductItem}
          />
          <Route path="/product/:prodName" exact component={SingleProducts} />
          <Route path="/wishlist" component={Wishlist} />
          <Route
            path="/terms-and-conditions"
            exact
            component={TermsConditions}
          />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />
          <Route path="/products/" exact component={Products} />
          <Route path="/member" exact component={MembershipForm} />
          <Route path="/contact-us" exact component={Contactus} />
          <Route path="/notifications" exact component={Notifications} />
          <Route path="/cart/" component={Cart} />
          <Route path="/redirect" exact component={Redirect} />
          <Route path="/app-details" exact component={AppDetialPage} />
        </div>
      </Switch>
    </>
  );
};

export default Routes;
