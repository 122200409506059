import { useEffect, useState } from "react";
import AllCategoriesCard from "../../components/Categories/AllCategoriesCard";
import SkeletionCategory from "../../components/Categories/SkeletionCategory";
import TopHeader from "../../components/TopHeader/TopHeader";
import url from "../../config/axios";
import EmptiedWishlist from "../../components/Profile/EmptiedWishlist";

function Allcategories() {
  const [category, setCategory] = useState([]);
  const [categoryData, setCategoryData] = useState(false);
  const [skel, setskel] = useState(true);

  console.log(category);
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  useEffect(() => {
    url
      .get(`/category/outlet?OutletId=${chainID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setskel(false);
          setCategory(res.data.data);
          setCategoryData(false);
        } else {
          setCategoryData(true);
          // alert(res.data.data);
        }
      })
      .catch(() => {
        setCategoryData(true);
      });
  }, [chainID]);

  return (
    <>
      <TopHeader pageName={"Categories"} total={category.length} />
      <div
        className="container"
        style={{ maxWidth: "100%", paddingBottom: "102px" }}>
        {!categoryData ? (
          <div className="all-cats ">
            {skel ? (
              <>
                {" "}
                <SkeletionCategory /> <SkeletionCategory />{" "}
                <SkeletionCategory /> <SkeletionCategory />{" "}
              </>
            ) : (
              <>
                {category.map((cat, i) => (
                  <AllCategoriesCard
                    productCount={cat?.countInventory[0]?.count}
                    name={cat?.name}
                    image={cat?.image}
                    key={i}
                    categoryID={cat?.categoryID}
                  />
                ))}
              </>
            )}
          </div>
        ) : (
          <EmptiedWishlist message={"Categories"} />
        )}
      </div>
    </>
  );
}

export default Allcategories;
