/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ChatContext } from "../../Contexts/ChatContext";
import url from "../../config/axios";
import Swal from "sweetalert2";

// import "bootstrap/dist/css/bootstrap.min.css";
import "./SearchBar.css";

function SearchBar(props) {
  const { setSearchText } = useContext(ChatContext);

  //console.log(props);
  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  const history = useHistory();

  useEffect(() => {
    searchbarLength();
    setSearchText(text);
    setSuggestions(props?.searchSuggestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, props?.searchSuggestions]);

  const onSuggestionsHandler = (text) => {
    history.push(`/product/` + text);
    window.location.reload();
    setText("");
    setSuggestions([]);
  };

  const searchbarLength = () => {
    if (text.length === 0) {
      setSuggestions([]);
    }
  };

  // const handleKeyDown = (event) => {
  //   if (!text) return;
  //   if (event.key === "Enter") {
  //     history.push(`/products/search/` + text);
  //     window.location.reload();
  //   }
  // };

  const handleKeyDown = (event) => {
    if (!text) return;
    if (event.key === "Enter") {
      if (text === "null") {
        setSuggestions([]);
      } else {
        url
          .get(`/inventory/search/?&outletChainID=${chainID}&name=${text}`)
          .then(async (res) => {
            if (res.data.Message === "Success") {
              setSuggestions(res.data.data);
            }
            if (res.data.data.length <= 0) {
              return Swal.fire("Oops! ", "Product not found", "warning");
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  return (
    <>
      <div style={{ width: "135%" }} className="input-group">
        <input
          type="text"
          onKeyDown={handleKeyDown}
          placeholder={"Search for Items"}
          onChange={(e) => setText(e.target.value)}
          value={text}
          style={{ width: "100%" }}
          className="navbar-search-input"
        />
      </div>
      {text === "" ? null : (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <ul
            className="dropdown-menu dropdown-search drp-menu ml-4 searchdrop"
            style={{ display: suggestions.length ? "block" : "none" }}>
            {suggestions &&
              suggestions.map((suggestion, i) => {
                return (
                  <a onClick={() => onSuggestionsHandler(suggestion.productID)}>
                    <li
                      className="dropdown-item"
                      key={i}
                      style={{
                        cursor: "pointer",
                        height: "30px",
                        marginTop: "-10px",
                      }}>
                      <span style={{ fontSize: "14px" }}>
                        {suggestion.product.name}
                      </span>
                    </li>
                  </a>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
}

export default SearchBar;
