import { useEffect, useState } from "react";
import leftLeaf from "../../images/left_leaf_info.png";
import RightLeaf from "../../images/right_leaf_info.png";
import config from "../../config";
import url from "../../config/axios";
import "./Infobanner.css";
import Spinner from "react-bootstrap/Spinner";

export default function Infobanner() {
  const [infoTxt, setInfoTxt] = useState([]);
  const [headLogo, setheadLogo] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  let logo = require("../../images/" + config.footerLogo);

  //console.log(outlets[0]);

  useEffect(() => {
    url.get(`/v1/general-settings/info`).then((res) => {
      // console.log(res);
      if (res.data.Message === "Success") {
        setheadLogo(res?.data?.data?.media?.footerLogoFileSource);
        setInfoTxt(res?.data?.data?.info?.mainHeading);
        if (!res?.data?.data?.media?.mainLogoFileSource) setIsLoaded(true);
      } else {
        // alert("Not", res.data.data);
      }
    });
  }, []);

  return (
    <div className="infobannerDiv">
      <div className="row" style={{ width: "100%" }}>
        {/* <div className="col-3" style={{ padding: "0px" }}>
          <img
            src={RightLeaf}
            alt="RightLeaf"
            style={{ width: "315px", height: "406px" }}
          />
        </div> */}
        <div
          className="col"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div className="infoBannerTxt">
            {!isLoaded ? (
              <Spinner
                style={{ width: "80px", height: "80px" }}
                animation="grow"
                variant="light"
              />
            ) : (
              ""
            )}
            <div style={{ maxHeight: "200px" }}>
              <img
                className="infobannerlogo"
                src={headLogo ? headLogo : logo}
                style={{ display: !isLoaded ? "none" : "flex" }}
                onLoad={() => {
                  setIsLoaded(true);
                }}
                alt="homebannerLogo"
              />
            </div>
            {infoTxt ? (
              <p style={{ marginTop: "35px" }}>{infoTxt}</p>
            ) : (
              <span style={{ marginTop: "25px" }}>
                More recently, scientists have discovered that certain
                components of cannabis, including CBD, are responsible for its
                pain-relieving effects.
              </span>
            )}
          </div>
        </div>

        {/* <div className="col-3" style={{ padding: "0px" }}>
          <img
            src={leftLeaf}
            alt="left"
            style={{ width: "100%", height: "406px" }}
          />
        </div> */}
      </div>

      {/* {outlets?
          <>
          <div style={{display:"flex"}}>
            <div style={{paddingRight:"25px",fontFamily: "Oswald",fontWeight:"400"}}>
              { outlets[0]?.mainHeading ? outlets[0]?.mainHeading : null }   
            </div>
            <div style={{ textDecoration: "underline", textDecorationColor: "#7FF9EA", textUnderlinePosition: "under" ,fontFamily: "Oswald"}}>
              {outlets[0]?.headerLink?.length < 50
               ?<a style={{color:"white"}} href={outlets[0]?.headerLink ? outlets[0]?.headerLink : null } > {outlets[0]?.headerLink ? outlets[0]?.headerLink : null }</a>  
               :<a style={{color:"white"}} href={outlets[0]?.headerLink ? outlets[0]?.headerLink : null } > {outlets[0]?.headerLink ? outlets[0]?.headerLink?.substring(0, 50) : null }</a>}   
            </div>
           </div>
           </>:null} */}
    </div>
  );
}
