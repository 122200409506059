import moment from "moment";
import React, { useContext } from "react";
import Swal from "sweetalert2";
import { ChatContext } from "../../Contexts/ChatContext";
import url from "../../config/axios";
import notify from "../../Icons/notify-icon.svg";
import { Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import config from "../../config";
import "./notification.css";

function NotificationModal({ Nshow, setNShow }) {
  const { setNotificationCount } = useContext(ChatContext);

  const [notifications, setNotifications] = React.useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const loggedIn = localStorage.getItem("user") ? true : false;
  const token = JSON.parse(localStorage.getItem("Etoken"));
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  const handleClose = () => setNShow(false);

  React.useEffect(() => {
    //fetch notifications from server
    GetNotifications();
  }, []);

  const GetNotifications = () => {
    url
      .get(`/v1/consumer-notifications-user/get-all-notifications-aggregated`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then(async (res) => {
        //console.log("Here is my notifications", res.data.data.notifications);
        if (res.data.Message === "Success") {
          let CurrentDayNotification = res.data.data.notifications.filter(
            (x) => {
              console.log(moment(x.createdAt).format("YYYY-MM-DD"));
              return (
                moment(x.createdAt).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD")
                // x.createdAt.split("T")[0].replace(/-/g, " ") ==
                // moment().format("YYYY MM DD")
              );
            }
          );

          // console.log("CurrentDayNotification",CurrentDayNotification)
          setNotifications(CurrentDayNotification);
        } else {
          //  alert(res.data.data);
        }
      });
  };

  // const markAllAsRead = () => {
  //   url
  //     .patch(`/v1/notifications/toggle-seen-for-users?userId=${user[0].userID}`)
  //     .then(async (res) => {
  //       if (res.data.Message === "Success") {
  //         GetNotifications();
  //         setNotificationCount(0);
  //         Swal.fire("Successfully", "Status updated successfully", "success");
  //       } else {
  //         //  alert(res.data.data);
  //       }
  //     });
  // };
  // console.log(notifications);
  return (
    <>
      <Modal
        className="NotificationModal"
        show={Nshow}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter">
        <Row className="NotificationDropdown" id="dropdown">
          <div className="div-alignment">
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                className="svgColor"
                d="M9.44319 18.5172C10.4902 18.5172 11.3559 17.6111 11.3559 16.5037H7.53027C7.53044 17.6111 8.39621 18.5172 9.44319 18.5172Z"
                fill="#08754C"
              />
              <path
                className="svgColor"
                d="M4.14746 12.618C5.27501 12.9804 7.18793 13.4838 9.42296 13.4838C11.7184 13.4838 13.5909 13.0005 14.7184 12.618C14.195 11.873 13.8727 10.9669 13.8727 10.0607V7.24183C13.8727 5.63099 13.027 4.20136 11.7384 3.41616C11.7384 3.37597 11.7585 3.3557 11.7585 3.31551C11.7585 2.04698 10.7115 1 9.44301 1C8.17448 1 7.1275 2.04698 7.1275 3.31551V3.39606C5.81862 4.16119 4.95284 5.61094 5.01326 7.24184V10.0608C4.93271 10.9267 4.67097 11.873 4.14749 12.6181L4.14746 12.618ZM9.44294 1.80515C10.188 1.80515 10.792 2.32862 10.933 3.03334C10.5102 2.8925 10.047 2.81195 9.58397 2.81195H9.30213C8.83908 2.81195 8.39601 2.8925 7.97313 3.0335C8.09387 2.32883 8.71803 1.80515 9.44294 1.80515L9.44294 1.80515Z"
                fill="#08754C"
              />
              <path
                className="svgColor"
                d="M3.141 13.8864L3 14.1079C3 15.1549 5.87937 16.0006 9.44344 16.0006C13.0075 16.0006 15.8869 15.1549 15.8869 14.1079L15.7459 13.8864L15.2626 13.2823C14.115 13.6851 12.0208 14.269 9.44372 14.269C6.90665 14.269 4.7524 13.665 3.64484 13.2823L3.141 13.8864Z"
                fill="#08754C"
              />
            </svg>
            <p className="notification-heading">Notifications</p>
            {/* <p className="mark-all" onClick={()=> markAllAsRead()}>Mark all as read</p> */}
          </div>
          <div className="newScrollBar">
            {notifications.map((notification) => {
              return (
                <div className="notification-gap">
                  <div style={{ display: "flex" }}>
                    {(notification?.intentTo == "ALL" &&
                      notification?.seenBy != null) ||
                    (notification?.intentTo == "INDIVIDUAL" &&
                      notification?.isSeen == false) ? (
                      <>
                        <svg
                          className="PrimaryOnlySvg"
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle
                            className="PrimaryOnlySvg"
                            cx="17"
                            cy="17"
                            r="17"
                            fill="#08754C"
                          />
                          <path
                            d="M11.9453 15.541C11.9453 16.926 11.7949 20.1703 10.3476 20.5308L10.0656 20.6067L10.0469 23.2061H14.9338C15.1594 24.1927 15.9864 24.724 17.0014 24.724C18.0164 24.724 18.8434 24.1927 19.069 23.2061H23.956L23.9372 20.6258L23.655 20.5499C22.2078 20.1895 22.0574 16.9262 22.0574 15.5601C22.0574 12.2968 20.065 11.0066 18.6177 10.4942C18.6552 10.3614 18.6742 10.2287 18.6742 10.0958C18.6742 9.16606 17.9224 8.42615 17.0201 8.42615C16.1179 8.42615 15.366 9.18497 15.366 10.0958C15.366 10.2287 15.3847 10.3614 15.4224 10.4942C13.9562 10.9874 11.9451 12.2775 11.9451 15.5409L11.9453 15.541ZM17.0013 23.965C16.4563 23.965 15.9676 23.6425 15.7232 23.1681H18.2607C18.0352 23.6425 17.5465 23.965 17.0014 23.965H17.0013ZM15.9865 11.0825L16.4564 10.9496L16.2121 10.5322C16.1369 10.3994 16.0994 10.2286 16.0994 10.0768C16.0994 9.56464 16.5128 9.16622 17.0015 9.16622C17.4902 9.16622 17.9036 9.58359 17.9036 10.0768C17.9036 10.2286 17.8661 10.3994 17.7909 10.5322L17.5466 10.9496L18.0165 11.0825C19.5202 11.4998 21.287 12.6192 21.287 15.541C21.287 19.4306 22.2833 20.7015 23.1854 21.119V22.2573L10.7988 22.2571V21.1188C11.7198 20.7204 12.6972 19.4302 12.6972 15.5409C12.6972 12.6381 14.4829 11.5186 15.9866 11.0823L15.9865 11.0825Z"
                            fill="white"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle
                            opacity="0.1"
                            cx="17"
                            cy="17"
                            r="17"
                            fill="#666666"
                          />
                          <path
                            d="M11.9453 15.541C11.9453 16.926 11.7949 20.1703 10.3476 20.5308L10.0656 20.6067L10.0469 23.2061H14.9338C15.1594 24.1927 15.9864 24.724 17.0014 24.724C18.0164 24.724 18.8434 24.1927 19.069 23.2061H23.956L23.9372 20.6258L23.655 20.5499C22.2078 20.1895 22.0574 16.9262 22.0574 15.5601C22.0574 12.2968 20.065 11.0066 18.6177 10.4942C18.6552 10.3614 18.6742 10.2287 18.6742 10.0958C18.6742 9.16606 17.9224 8.42615 17.0201 8.42615C16.1179 8.42615 15.366 9.18497 15.366 10.0958C15.366 10.2287 15.3847 10.3614 15.4224 10.4942C13.9562 10.9874 11.9451 12.2775 11.9451 15.5409L11.9453 15.541ZM17.0013 23.965C16.4563 23.965 15.9676 23.6425 15.7232 23.1681H18.2607C18.0352 23.6425 17.5465 23.965 17.0014 23.965H17.0013ZM15.9865 11.0825L16.4564 10.9496L16.2121 10.5322C16.1369 10.3994 16.0994 10.2286 16.0994 10.0768C16.0994 9.56464 16.5128 9.16622 17.0015 9.16622C17.4902 9.16622 17.9036 9.58359 17.9036 10.0768C17.9036 10.2286 17.8661 10.3994 17.7909 10.5322L17.5466 10.9496L18.0165 11.0825C19.5202 11.4998 21.287 12.6192 21.287 15.541C21.287 19.4306 22.2833 20.7015 23.1854 21.119V22.2573L10.7988 22.2571V21.1188C11.7198 20.7204 12.6972 19.4302 12.6972 15.5409C12.6972 12.6381 14.4829 11.5186 15.9866 11.0823L15.9865 11.0825Z"
                            fill="#959B97"
                          />
                        </svg>
                      </>
                    )}

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        className={
                          (notification?.intentTo == "ALL" &&
                            notification?.seenBy != null) ||
                          (notification?.intentTo == "INDIVIDUAL" &&
                            notification?.isSeen == false)
                            ? "notification-text-status"
                            : "notification-text"
                        }>
                        {notification?.notificationInfo?.title}
                      </p>

                      <p
                        className={
                          (notification?.intentTo == "ALL" &&
                            notification?.seenBy != null) ||
                          (notification?.intentTo == "INDIVIDUAL" &&
                            notification?.isSeen == false)
                            ? "notification-time-status"
                            : "notification-time"
                        }>
                        {moment(
                          new Date(notification?.createdAt).getTime()
                        ).fromNow()}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="see-all-div">
            <a href="/notifications">
              <p className="see-all-text">See All</p>
            </a>
          </div>
        </Row>
      </Modal>
    </>
  );
}

export default NotificationModal;
