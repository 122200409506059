import React, { useEffect, useState, useContext } from "react";
import CartDetails from "../../components/Cart/CartDetails";
import CartItem from "../../components/Cart/CartItem";
import EmptiedWishlist from "../../components/Profile/EmptiedWishlist";
import { ChatContext } from "../../Contexts/ChatContext";
import TopHeader from "../../components/TopHeader/TopHeader";
import url from "../../config/axios";
import Swal from "sweetalert2";
import SkeletionLoadingCart from "../../components/Profile/SkeletionLoadingCart";
import config from "../../config";

function Cart() {
  const { setCartCount, redirectUser } = useContext(ChatContext);

  const [cart, setCart] = useState([]);
  const [updateCartCount, setUpdateCartCount] = useState([]);
  const [skel, setskel] = useState(true);
  const [totalProd, setTotalProd] = useState(0);
  const [deleteProdCoupon, setDeleteProdCoupon] = useState(0);

  // console.log(cart);

  const token = JSON.parse(localStorage.getItem("Etoken"));
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  const uid = JSON.parse(localStorage.getItem("user"));
  localStorage.setItem(
    "user",
    JSON.stringify(redirectUser.length !== 0 ? redirectUser : uid)
  );

  let loggedIn =
    localStorage.getItem("user") && uid?.length !== 0 && uid !== null
      ? true
      : false;

  useEffect(() => {
    if (!loggedIn) return;
    getCart();
    getQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getCart = () => {
    if (!loggedIn) return;
    url
      .get(`/v1/user-cart/get-plain?outletChainID=${chainID}`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setCart(res.data.data.cart);
          setCartCount(res?.data?.data?.cart?.items?.length);
          setTotalProd(res?.data?.data?.cart?.items?.length);
          setskel(false);
          setDeleteProdCoupon(0);
        }
      });
  };

  const getQuote = () => {
    const requestData = {
      outletChainID: chainID,
    };
    url
      .put("/v1/user-orders-users/get-quote", requestData, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then((res) => {
        if (res.data.Message === "Success") {
          setUpdateCartCount(res?.data?.data?.quote);
        } else {
          Swal.fire("Oops", res.data.data.Message, "warning");
          console.log("fail");
        }
      })
      .catch((e) => {
        if (
          e.response.data.Message === "Failure" &&
          e.response?.data?.data?.quote
        ) {
          setUpdateCartCount(e.response?.data?.data?.quote);
        }
        Swal.fire("Oops", e.response.data.data.message, "warning");
        // console.log(e.response);
      });
  };

  const UpdateQuantity = (c, pq) => {
    let variant = c.productInfo.variant;
    if (loggedIn) {
      url
        .put(
          `/v1/user-cart/update-item`,
          {
            variant,
            productID: c.productID,
            targetDealID: c.targetDealID ? c.targetDealID : "",
            outletChainID: chainID,
            purchaseQuantity: pq,
          },
          {
            headers: {
              outletchainid: chainID,
              consumerid: config.consumerId,
              auth: token,
            },
          }
        )
        .then(async (res) => {
          if (res.data.Message === "Success") {
            getCart();
            getQuote();
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "warning",
              title: "Cart updated successfully",
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "warning",
              title: res.data.message,
            });
          }
        });
    }
  };

  const removeCart = (c) => {
    //return console.log(c);
    let variant = c.productInfo.variant;
    if (loggedIn) {
      url
        .put(
          `/v1/user-cart/update-item`,
          {
            variant,
            productID: c.productID,
            targetDealID: c.targetDealID ? c.targetDealID : "",
            outletChainID: chainID,
            purchaseQuantity: 0,
          },
          {
            headers: {
              outletchainid: chainID,
              consumerid: config.consumerId,
              auth: token,
            },
          }
        )
        .then(async (res) => {
          getCart();
          getQuote();
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Product successfully deleted",
          });
        });
    }
  };

  const chkoutremove = () => {
    url
      .delete(`/v1/user-cart/clear?outletChainID=${chainID}`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then(async (res) => {
        //await getCart();
      });
    setCart([]);
    setCartCount(0);
  };

  return (
    <>
      <TopHeader pageName={"My Cart"} />

      <div className="cart">
        {totalProd === 0 || !totalProd ? (
          <EmptiedWishlist message={"cart"} />
        ) : (
          <div
            className="row"
            style={{ textAlign: "-webkit-center", marginTop: "50px" }}>
            <div className="upper-title">
              <div
                className="row"
                style={{ width: "100%", alignItems: "center" }}>
                <div
                  className="col-5 mycartleft"
                  style={{ width: "25%" }}></div>
              </div>
            </div>
            <div className="col carditemleft">
              <div className="cart-topbar">
                <div className="cart-topbar-products">Products</div>

                <div className="cart-topbar-product-length">
                  <span>{totalProd}</span>&nbsp;
                  <span>{totalProd <= 1 ? "Item" : "Items"}</span>
                </div>
              </div>
              <div className="cart-align" style={{ marginBottom: "80px" }}>
                {skel ? (
                  <SkeletionLoadingCart />
                ) : (
                  updateCartCount?.lineItemsInfo?.lineItems?.map((c, i1) => {
                    // console.log(c);
                    return (
                      <CartItem
                        product={c?.productInfo.rootProduct}
                        cart={c}
                        variant={c?.productInfo.variant}
                        cardId={cart?.cartId}
                        index={i1}
                        removeCart={removeCart}
                        UpdateQuantity={UpdateQuantity}
                      />
                    );
                  })
                )}
              </div>
            </div>
            <div className="col" style={{ display: "flex" }}>
              <CartDetails
                updateCartCount={updateCartCount}
                chkoutremove={chkoutremove}
                deleteProdCoupon={deleteProdCoupon}
                currency={
                  cart?.products?.length > 0
                    ? cart?.products[0]?.variant?.pricingInfo?.currency
                      ? cart?.products[0]?.variant?.pricingInfo?.currency ===
                        "USD"
                        ? "$"
                        : cart?.products[0]?.variant?.pricingInfo?.currency +
                          "$"
                      : "$"
                    : "$ "
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Cart;
